import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import globalVariables from "../../globalVariables"
import TitleV2 from "../../elements/TitleV2/TitleV2"
import ArrowWhite from "../../../images/icons/left-arrow-white.svg"
import ArrowPurple from "../../../images/icons/left-arrow-purple.svg"

const Text = styled.p`
  font-size: 0;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 0;
  color: ${props => props.theme.white};
  transition: font-size 0.1s ease-in-out;
`

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.lightGrey};
  padding: 25px 20px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 25px;
    height: 25px;
    background-image: url(${ArrowPurple});
    background-size: cover;
    transition: background-image 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${props => props.theme.secondaryDarkPurple};
    h3 {
      color: ${props => props.theme.white};
    }
    ${Text} {
      font-size: 14px;
    }
    &::before {
      background-image: url(${ArrowWhite});
    }
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    width: 322px;
    height: 218px;
  }
`

const Univers = styled.p`
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${props => props.theme.lightBlue};
`

const CardExpertise = ({ item }) => {
  const url = new URL(item.url).pathname
  return (
    <Card to={url}>
      <Univers>{item.univers}</Univers>
      <TitleV2 tag="h3" color="secondaryDarkPurple">
        {item.titre}
      </TitleV2>
      <Text>{item.texte}</Text>
    </Card>
  )
}

CardExpertise.propTypes = {
  item: PropTypes.shape({
    univers: PropTypes.string,
    titre: PropTypes.string,
    texte: PropTypes.string,
    url: PropTypes.string,
  }),
}

export default CardExpertise
