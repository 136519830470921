import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Expertises from "../components/Home/ExpertisesV2"
import Valeurs from "../components/Home/Valeurs"
import Accompagnement from "../components/Home/Accompagnement"
import Clients from "../components/Home/Clients"
import Certifications from "../components/Home/Certifications"
import Temoignage from "../components/Home/Temoignage"
import BlocCTA from "../components/Ebooks/BlocCTA"
import Blog from "../components/Home/Blog"
import Contact from "../components/Home/Contact"
import Faq from "../components/elements/Faq"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  {
    content: "Nos expertises",
    anchor: "expertises",
  },
  {
    content: "Vos besoins",
    anchor: "besoins",
  },
  {
    content: "Nos accompagnements",
    anchor: "accompagnements",
  },
]

const bannerLeftCardContent = {
  mainContent: `Déployez une stratégie digitale performante en vous appuyant sur l'expertise de nos
    consultants et sur une méthodologie Data Driven et User Centric adaptée à vos enjeux et
    objectifs.`,
  titleFirstLine: "Agence experte en",
  titleSecondLine: "stratégie digitale",
  subTitle: "100% Data Driven & User Centric",
}

const IndexPage = ({ data, location }) => {
  //get WordPress data

  const {
    accueil,
    bloc_references,
    bloc_certifications,
    bloc_temoignages,
    bloc_ebook_cta,
    articles,
    bloc_contact,
  } = data

  return (
    <Layout locationGTM={location.href} location={location.pathname}>
      <SEO
        title={accueil.acf.title}
        description={accueil.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasTypeform
        isHomePage
        bannerLeftCardContent={bannerLeftCardContent}
      />
      <Expertises
        intro={accueil.acf.intro_expertise}
        text={accueil.acf.texte_expertise}
        items={accueil.acf.liste_expertise}
      />
      <Valeurs title={accueil.acf.titre_valeurs} items={accueil.acf.valeurs} />
      <Accompagnement
        title={accueil.acf.titre_accompagnement}
        text={accueil.acf.texte_accompagnement}
        items={accueil.acf.accompagnements}
      />
      <Clients
        title={bloc_references.acf.titre_references}
        text={bloc_references.acf.texte_reference}
        items={bloc_references.acf.reference}
      />
      <Certifications
        title={bloc_certifications.acf.titre_certification}
        text={bloc_certifications.acf.texte_certification}
        items={bloc_certifications.acf.certifications}
      />
      <Temoignage
        title={bloc_temoignages.acf.titre_temoignages}
        items={bloc_temoignages.acf.temoignages}
      />
      <Blog
        articles={articles.edges.slice(Math.max(articles.edges.length - 3, 0))}
        title="Derniers articles en direct du blog"
      />
      <BlocCTA
        title={bloc_ebook_cta.acf.titre_ebook}
        description={bloc_ebook_cta.acf.texte_ebook}
        imageBanner={data.bannerEbook.childImageSharp.fluid}
        imageHeight="430px"
        imageMobileHeight="426px"
      />
      <Faq
        title={accueil.acf.titre_faq}
        text={accueil.acf.texte_faq}
        themes={accueil.acf.themes_faq}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    accueil: wordpressPage(id: { eq: "5bdb8651-d67f-5583-90ba-fc044461c6ab" }) {
      acf {
        title
        meta_description
        titre_banniere_1_new
        titre_banniere_2
        titre_banniere_3_new
        titre_banniere_4
        titre_banniere_partie_dynamique_new {
          element
        }
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        intro_expertise
        texte_expertise
        liste_expertise {
          texte
          titre
          univers
          url
        }
        titre_valeurs
        valeurs {
          intro_valeur
          texte_valeur
          titre_valeur
        }
        titre_faq
        texte_faq
        themes_faq {
          titre_theme_faq
          questions_faq {
            question_faq
            reponse_faq
            lien_faq
            texte_bouton_faq
          }
        }
        titre_accompagnement
        texte_accompagnement
        accompagnements {
          texte_accompagnement
          titre_accompagnement
          icone_accompagnement {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
        titre_blog
      }
    }
    bloc_references: wordpressAcfBlocs(
      id: { eq: "c995352c-c671-54ba-872f-0404f5c8a456" }
    ) {
      acf {
        titre_references
        texte_reference
        reference {
          couleur_reference
          lien_reference
          image_reference {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo_reference {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 200, maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    bloc_certifications: wordpressAcfBlocs(
      id: { eq: "b093d1ee-95cf-54db-b63c-5f4803c934fb" }
    ) {
      acf {
        titre_certification
        texte_certification
        certifications {
          logo_certification {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    bloc_temoignages: wordpressAcfBlocs(
      id: { eq: "bb45179a-ff3e-53bb-8add-80a2dec1388b" }
    ) {
      acf {
        titre_temoignages
        temoignages {
          nom_temoignage
          texte_temoignage
          fonction_temoignage
          logo_temoignage {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 200, maxWidth: 200) {
                  src
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    bloc_ebook_cta: wordpressAcfBlocs(
      id: { eq: "a3c88e12-40c2-5421-a689-7af9b4a67e10" }
    ) {
      acf {
        titre_ebook
        texte_ebook
      }
    }
    bannerEbook: file(relativePath: { eq: "ebooks/e-book-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articles: allWordpressPost(
      limit: 3
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            niveau_article
            titre_article
            intro_article
            date_de_publication
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            temps_de_lecture
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
