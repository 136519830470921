import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Parser from "html-react-parser"

import Section from "../../elements/Section"
import globalVariables from "../../globalVariables"
import TitleV2 from "../../elements/TitleV2/TitleV2"
import CardExpertise from "./CardExpertise"
import Buttonv2 from "../../elements/Buttonv2"
import universData from "../../../utils/universData"

import leftArrowWhite from "../../../images/icons/left-arrow-white.svg"
import leftArrowBlue from "../../../images/icons/left-arrow-light-blue.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;

  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
    width: 1200px;
    margin: 20px auto 40px auto;
    padding-left: 50px;
  }
`

const ColLeft = styled.div`
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    max-width: 400px;
  }
`

const ColRight = styled.div`
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    padding-left: 50px;
  }
`

const WrapperCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Intro = styled.p`
  font-size: 20px;
  font-weight: 900;
`

const Text = styled.div`
  p {
    strong {
      font-weight: 900;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
    gap: 20px;
  }
`

const Expertises = ({ intro, text, items }) => {
  const [selectedUnivers, setSelectedUnivers] = useState(null)
  const selectUnivers = univers =>
    setSelectedUnivers(univers === selectedUnivers ? null : univers)

  return (
    <Section padding="0" id="expertises">
      <Wrapper>
        <ColLeft>
          <TitleV2 color="lightBlue" tag="h2">
            Nos <b>expertises</b>
          </TitleV2>
          <Intro>{intro}</Intro>
          <Text>{Parser(text)}</Text>
        </ColLeft>
        <ColRight>
          <Buttons>
            {Object.values(universData).map(value => (
              <Buttonv2
                content={value}
                style={
                  selectedUnivers === value.toLowerCase()
                    ? "lightblueBackgroundWhiteFont"
                    : "whiteBackgroundlightBlueFont"
                }
                type="rounded-filter"
                iconLeft={
                  selectedUnivers === value.toLowerCase()
                    ? leftArrowWhite
                    : leftArrowBlue
                }
                handleClick={() => selectUnivers(value.toLowerCase())}
              />
            ))}
          </Buttons>
          <WrapperCards>
            {items.map(
              (item, index) =>
                (item.univers.toLowerCase() === selectedUnivers ||
                  selectedUnivers === null) && (
                  <CardExpertise
                    item={item}
                    key={index}
                    selectedUnivers={selectedUnivers}
                  />
                )
            )}
          </WrapperCards>
        </ColRight>
      </Wrapper>
    </Section>
  )
}

Expertises.propTypes = {
  intro: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      univers: PropTypes.string,
      titre: PropTypes.string,
      texte: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default Expertises
