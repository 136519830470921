import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const P = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${props => props.theme[props.color] ?? props.theme.bodyColorV2};
`

const H2 = styled.h2`
  font-size: 48px;
  margin-bottom: 30px;
  color: ${props => props.theme[props.color] ?? props.theme.bodyColorV2};
  font-weight: 400;
`

const H3 = styled.h3`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 0;
  color: ${props => props.theme[props.color] ?? props.theme.bodyColorV2};
`

const TitleV2 = ({ color, className, children, tag }) => {
  const getTitle = () => {
    switch (tag) {
      case "h2":
        return (
          <H2 color={color} className={className}>
            {children}
          </H2>
        )
      case "h3":
        return (
          <H3 color={color} className={className}>
            {children}
          </H3>
        )
      default:
        return (
          <P color={color} className={className}>
            {children}
          </P>
        )
      // code block
    }
  }
  return getTitle()
}

TitleV2.propTypes = {
  tag: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
}

export default TitleV2
